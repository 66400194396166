import axios from "axios";
import { SuccessStoriesAction } from "./SuccessStoriesReducers";

export const getSuccessStories = () => async (dispatch) => {
  try {
    dispatch(SuccessStoriesAction.successStoriesRequest());
    const res = await axios.get(process.env.REACT_APP_API + "/story");
    dispatch(SuccessStoriesAction.successStoriesSuccess(res.data.data));
  } catch (error) {
    dispatch(SuccessStoriesAction.successStoriesFail(error.message));
  }
};
