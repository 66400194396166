import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  aboutUs: [],
};

const AboutUsSlice = createSlice({
  name: "AboutUsReducers",
  initialState,
  reducers: {
    AboutUsRequest: (state) => {
      state.loading = true;
    },
    AboutUsSuccess: (state, action) => {
      state.loading = false;
      state.aboutUs = action.payload;
    },
    AboutUsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const AboutUsActions = AboutUsSlice.actions;
export default AboutUsSlice;
