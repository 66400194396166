import React, { useState, useEffect, useRef } from "react";
import "./JobListing.css";
import heading from "../../assets/images/aboutus/Path 489.svg";
import { GrSearch } from "react-icons/gr";
import { IoIosArrowForward } from "react-icons/io";
import { BsFillTrashFill } from "react-icons/bs";
import JobListingCard from "../../Components/Profile/JobListCard";
import axios from "axios";
import { getJobs } from "../../Redux/jobs/JobsActions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader/Loader";
import { getsavedjobs } from "../../Redux/Favorite/FavoriteActions";
import { useLocation } from "react-router-dom";

function JobListing(route) {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const category_id = searchParams.get("category");
  const [Categories, setCategories] = useState(false);
  const [jobtypes, setjobtypes] = useState(false);
  const [categorylist, setcategorylist] = useState([]);
  const [categoriesfilter, setcategoriesfilter] = useState("");
  const [districtslist, setdistrictslist] = useState("");
  const [districts, setdistricts] = useState(false);
  const [selecteddistrict, setselecteddistrict] = useState([]);
  const [educationallevellist, seteducationallevellist] = useState([]);
  const [selectededucationallevel, setselectededucationallevel] = useState([]);
  const [experiencelevellist, setexperiencelevellist] = useState([]);
  const [experience, setexperience] = useState(false);
  const [selectedexperiencelevel, setselectedexperiencelevel] = useState([]);
  const [favoritesJobsArray, setFavoritesJobsArray] = useState([]);
  const [education, seteducation] = useState(false);
  const [joblist, setjoblist] = useState(3);
  const { jobs } = useSelector((store) => store.Jobs);
  const { loading } = useSelector((state) => state.Jobs);
  const { savedjobs } = useSelector((store) => store.Favorites);

  const [selectedCategory, setSelectedCategory] = useState([]);

  //useeffect if categoryid is prsent to call   handleCategorySelection(category_id);

  const handleCategories = () => {
    setCategories(!Categories);
    setexperience(null);
    setjobtypes(null);
    setdistricts(null);
    seteducation(null);
  };
  const handletype = () => {
    setjobtypes(!jobtypes);
    setCategories(null);
    setexperience(null);
    setdistricts(null);
    seteducation(null);
  };
  const handledistricts = () => {
    setdistricts(!districts);
    setCategories(null);
    setjobtypes(null);
    setexperience(null);
    seteducation(null);
  };
  const handleeducation = () => {
    seteducation(!education);
    setCategories(null);
    setjobtypes(null);
    setdistricts(null);
    setexperience(null);
  };
  const handleexperience = () => {
    setexperience(!experience);
    setCategories(null);
    setjobtypes(null);
    setdistricts(null);
    seteducation(null);
  };
  const getexpertiselist = async () => {
    const res = await axios.get(process.env.REACT_APP_API + "/category/getAll");
    setcategorylist(res.data.data);
  };

  const getdistrictslist = async () => {
    const res = await axios.get(process.env.REACT_APP_API + "/disrict");
    setdistrictslist(res.data.data);
  };
  const getdegreelist = async () => {
    const res = await axios.get(process.env.REACT_APP_API + "/education");
    seteducationallevellist(res.data.data);
  };
  const getdexperiencelevellist = async () => {
    const res = await axios.get(process.env.REACT_APP_API + "/experience");
    setexperiencelevellist(res.data.data);
  };

  useEffect(() => {
    getexpertiselist();
    getdistrictslist();
    getdegreelist();
    getdexperiencelevellist();
    dispatch(getsavedjobs());
  }, [dispatch]);
  

  useEffect(() => {
    if (savedjobs?.data?.length > 0) {
      savedjobs?.data?.forEach((job) => {
        if (!favoritesJobsArray.includes(job?.job_id)) {
          setFavoritesJobsArray((prevFavoritesJobsArray) => [
            ...prevFavoritesJobsArray,
            job?.job_id,
          ]);
        }
      });
    }
  }, [savedjobs, favoritesJobsArray]);
  

  //usseeffect to call handleCategorySelection(category_id)
  useEffect(() => {
    if (category_id) {
      handleCategorySelection(category_id);
    }
  }, [category_id]);

  const handleCategorySelection = (categoryId) => {

    if (selectedCategory.includes(categoryId)) {
      const filteredArray = selectedCategory.filter(
        (element) => element !== categoryId
      );
      setSelectedCategory(filteredArray);
    } else {
      setSelectedCategory([...selectedCategory, categoryId]);
    }
  
  };
  
  const [selectedtype, setSelectedtype] = useState([]);

  const handletypeSelection = (typeName) => {
    if (selectedtype.includes(typeName)) {
      const filteredArray = selectedtype.filter(
        (element) => element !== typeName
      );
      setSelectedtype(filteredArray);
    } else {
      setSelectedtype([...selectedtype, typeName]);
    }
  };
  const handledistrictsSelection = (typeId) => {
    if (selecteddistrict.includes(typeId)) {
      const filteredArray = selecteddistrict.filter(
        (element) => element !== typeId
      );
      setselecteddistrict(filteredArray);
    } else {
      setselecteddistrict([...selecteddistrict, typeId]);
    }
  };

  const handleedducationchange = (typeId) => {
    if (selectededucationallevel.includes(typeId)) {
      const filteredArray = selectededucationallevel.filter(
        (element) => element !== typeId
      );
      setselectededucationallevel(filteredArray);
    } else {
      setselectededucationallevel([...selectededucationallevel, typeId]);
    }
  };
  const handleexperiencechange = (typeId) => {
    if (selectedexperiencelevel.includes(typeId)) {
      const filteredArray = selectedexperiencelevel.filter(
        (element) => element !== typeId
      );
      setselectedexperiencelevel(filteredArray);
    } else {
      setselectedexperiencelevel([...selectedexperiencelevel, typeId]);
    }
  };
  const [search, setSearch] = useState("");
  const jobtype = [
    { id: 1, name: "Full Time" },
    { id: 2, name: "Part Time" },
    { id: 3, name: "Remotely" },
    { id: 4, name: "Internship" },
    { id: 5, name: "Freelance" },
    { id: 6, name: "Temporary" },
    { id: 7, name: "Volunteer" },
  ];
  const handlereset = () => {
    setSelectedCategory([]);
    setSelectedtype([]);
    setselecteddistrict([]);
    setselectededucationallevel([]);
    setselectedexperiencelevel([]);
    setCategories(null);
    setjobtypes(null);
    setdistricts(null);
    seteducation(null);
    setexperience(null);
    setSearch("");
  };
  // useEffect(() => {
  //   dispatch(
  //     getJobs({
  //       district_id: selecteddistrict,
  //       expertise_id: selectedCategory,
  //       job_type: selectedtype,
  //       education_level_id: selectededucationallevel,
  //       experience_level_id: selectedexperiencelevel,
  //       search: search,
  //     })
  //   );
  // }, [
  //   selecteddistrict,
  //   selectedCategory,
  //   selectedtype,
  //   selectededucationallevel,
  //   selectedexperiencelevel,
  //   search,
  // ]);
  useEffect(() => {
   
    dispatch(
      getJobs({
        district_id: selecteddistrict,
        expertise_id: selectedCategory,
        job_type: selectedtype,
        education_level_id: selectededucationallevel,
        experience_level_id: selectedexperiencelevel,
        search: search,
      })
    );
  }, [selecteddistrict, selectedCategory, selectedtype, selectededucationallevel, selectedexperiencelevel, search]);
  
  const droplistRef = useRef(null);

  // Use an effect to attach a click event listener when the component mounts
  useEffect(() => {
    function handleClickOutside(event) {
      if (droplistRef.current && !droplistRef.current.contains(event.target)) {
        setCategories(null);
        setjobtypes(null);
        setdistricts(null);
        seteducation(null);
        setexperience(null);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="Main_job_listing">
      <div className="Aboutus_heading">
        <div className="Aboutus_heading_img">
          <img src={heading} alt="heading" className="heading" />
        </div>

        <div className="Aboutus_heading_text">
          <div className="Aboutus_heading_text_title_2">
            {" "}
            List of Careers and Jobs
          </div>
          {/* <div className="Aboutus_heading_text_desc">
            {" "}
            Home/ <span className="head_span">Companies Registration</span>
          </div> */}
        </div>
        <div className="Aboutus_heading_text">
          <div className="Aboutus_heading_text_desc">
            {" "}
            <a href="/">Home /</a> <span className="head_span">Jobs</span>
          </div>
        </div>
      </div>
      <div className="joblisting_body">
        <div className="filter_bar">
          <div className="upperfilters_box">
            <div className="search_box">
              <label className="cont_search">
                <GrSearch size={30} className="search_icon" />
                <input
                  type="text"
                  className="input_complete_search"
                  placeholder="Search for Keywords"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </label>
            </div>
          </div>
          <div ref={droplistRef} className="filter_icons_container">
            <div className="icons_drop">
              <div className="icons_dropdown">
                <div className="title_drop_cont" onClick={handleCategories}>
                  <div className="title_drop">Categories</div>
                  <div className="arrow_cont">
                    <IoIosArrowForward
                      size={30}
                      className={Categories ? "arrow_icon_rot" : "arrow_icon"}
                    />
                  </div>
                </div>
                {Categories && (
                  <div className="Header_nav_dropdown_job">
                    <div className="Header_nav_dropdown_cont">
                      {categorylist.map((category) => (
                        <div key={category.id} className="subsection_text_jobs">
                          <input
                            type="checkbox"
                            value={category.id}
                            name="category_filter"
                            className="checkbox_filter"
                            id={`category_${category?.id}`}
                            checked={selectedCategory.includes(category?.id)}
                            onChange={() => {
                              handleCategorySelection(category?.id);
                            }}
                          />
                          <label htmlFor={`category_${category?.id}`}>
                            {category.title}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <div ref={droplistRef} className="icons_dropdown">
                <div className="title_drop_cont" onClick={handletype}>
                  <div className="title_drop">Job Type</div>
                  <div className="arrow_cont">
                    <IoIosArrowForward
                      size={30}
                      className={jobtypes ? "arrow_icon_rot" : "arrow_icon"}
                    />
                  </div>
                </div>
                {jobtypes && (
                  <div className="Header_nav_dropdown_job_2">
                    <div className="Header_nav_dropdown_cont">
                      {/* <div className="subsection_text">Sucess Stories</div>
                      <div className="subsection_text">Media Content</div>
                      <div className="subsection_text">Q&A </div> */}
                      {/* {categorylist.map((item) => (
                        <div className="subsection_text_jobs" key={item.id}>
                          {" "}
                          <input
                            type="checkbox"
                            className="checkbox_filter"
                            value={categoriesfilter}
                            name="category_filter"
                            id={`category_${item.id}`}
                            onChange={(e) =>
                              setcategoriesfilter(e.target.value)
                            }
                          />
                          <label htmlFor={`category_${item.id}`}>
                            {item.name_en}{" "}
                          </label>
                        </div>
                      ))} */}
                      {jobtype.map((type) => (
                        <div key={type.id} className="subsection_text_jobs">
                          <input
                            type="checkbox"
                            value={type.name} // Store the name instead of the ID
                            name="category_filter"
                            className="checkbox_filter"
                            id={`type_${type.id}`}
                            checked={selectedtype.includes(type.name)} // Compare with selectedTypeName
                            onChange={() => handletypeSelection(type.name)} // Pass the name to handletypeSelection
                          />
                          <label htmlFor={`type_${type.id}`}>{type.name}</label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div ref={droplistRef} className="icons_dropdown">
                <div className="title_drop_cont" onClick={handledistricts}>
                  <div className="title_drop">Job Location</div>
                  <div className="arrow_cont">
                    <IoIosArrowForward
                      size={30}
                      className={districts ? "arrow_icon_rot" : "arrow_icon"}
                    />
                  </div>
                </div>
                {districts && (
                  <div className="Header_nav_dropdown_job_2">
                    <div className="Header_nav_dropdown_cont">
                      {/* <div className="subsection_text">Sucess Stories</div>
                      <div className="subsection_text">Media Content</div>
                      <div className="subsection_text">Q&A </div> */}
                      {/* {categorylist.map((item) => (
                        <div className="subsection_text_jobs" key={item.id}>
                          {" "}
                          <input
                            type="checkbox"
                            className="checkbox_filter"
                            value={categoriesfilter}
                            name="category_filter"
                            id={`category_${item.id}`}
                            onChange={(e) =>
                              setcategoriesfilter(e.target.value)
                            }
                          />
                          <label htmlFor={`category_${item.id}`}>
                            {item.name_en}{" "}
                          </label>
                        </div>
                      ))} */}
                      {districtslist.map((type) => (
                        <div key={type.id} className="subsection_text_jobs">
                          <input
                            type="checkbox"
                            value={type.id}
                            name="category_filter"
                            className="checkbox_filter"
                            id={`type_${type.id}`}
                            checked={selecteddistrict.includes(type.id)}
                            onChange={() => handledistrictsSelection(type.id)}
                          />
                          <label htmlFor={`type_${type.id}`}>
                            {type.name_en}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div ref={droplistRef} className="icons_dropdown">
                <div className="title_drop_cont" onClick={handleeducation}>
                  <div className="title_drop">Educational Level</div>
                  <div className="arrow_cont">
                    <IoIosArrowForward
                      size={30}
                      className={education ? "arrow_icon_rot" : "arrow_icon"}
                    />
                  </div>
                </div>
                {education && (
                  <div className="Header_nav_dropdown_job_2">
                    <div className="Header_nav_dropdown_cont">
                      {/* <div className="subsection_text">Sucess Stories</div>
                      <div className="subsection_text">Media Content</div>
                      <div className="subsection_text">Q&A </div> */}
                      {/* {categorylist.map((item) => (
                        <div className="subsection_text_jobs" key={item.id}>
                          {" "}
                          <input
                            type="checkbox"
                            className="checkbox_filter"
                            value={categoriesfilter}
                            name="category_filter"
                            id={`category_${item.id}`}
                            onChange={(e) =>
                              setcategoriesfilter(e.target.value)
                            }
                          />
                          <label htmlFor={`category_${item.id}`}>
                            {item.name_en}{" "}
                          </label>
                        </div>
                      ))} */}
                      {educationallevellist.map((type) => (
                        <div key={type.id} className="subsection_text_jobs">
                          <input
                            type="checkbox"
                            value={type.id}
                            name="category_filter"
                            className="checkbox_filter"
                            id={`type_${type.id}`}
                            checked={selectededucationallevel.includes(type.id)}
                            onChange={() => handleedducationchange(type.id)}
                          />
                          <label htmlFor={`type_${type.id}`}>
                            {type.name_en}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div ref={droplistRef} className="icons_dropdown">
                <div className="title_drop_cont" onClick={handleexperience}>
                  <div className="title_drop">Experience Level</div>
                  <div className="arrow_cont">
                    <IoIosArrowForward
                      size={30}
                      className={experience ? "arrow_icon_rot" : "arrow_icon"}
                    />
                  </div>
                </div>
                {experience && (
                  <div className="Header_nav_dropdown_job_2">
                    <div className="Header_nav_dropdown_cont">
                      {/* <div className="subsection_text">Sucess Stories</div>
                      <div className="subsection_text">Media Content</div>
                      <div className="subsection_text">Q&A </div> */}
                      {/* {categorylist.map((item) => (
                        <div className="subsection_text_jobs" key={item.id}>
                          {" "}
                          <input
                            type="checkbox"
                            className="checkbox_filter"
                            value={categoriesfilter}
                            name="category_filter"
                            id={`category_${item.id}`}
                            onChange={(e) =>
                              setcategoriesfilter(e.target.value)
                            }
                          />
                          <label htmlFor={`category_${item.id}`}>
                            {item.name_en}{" "}
                          </label>
                        </div>
                      ))} */}
                      {experiencelevellist.map((type) => (
                        <div key={type.id} className="subsection_text_jobs">
                          <input
                            type="checkbox"
                            value={type.id}
                            name="category_filter"
                            className="checkbox_filter"
                            id={`type_${type.id}`}
                            checked={selectedexperiencelevel.includes(type.id)}
                            onChange={() => handleexperiencechange(type.id)}
                          />
                          <label htmlFor={`type_${type.id}`}>
                            {type.name_en}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="title_drop_cont" onClick={handlereset}>
                <div className="trash_cont">
                  <BsFillTrashFill size={30} className="trash_icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="jobs_container">
          {loading ? (
            <Loader />
          ) : (
            <>
              {jobs &&
                jobs.slice(0, joblist).map((job) => {
                  let isSaved = false;
                  if (favoritesJobsArray.includes(job.id)) {
                    isSaved = true;
                  }
                  return (
                    <JobListingCard
                      position={job.title_en}
                      name={job.company.full_name}
                      location={job.district.name_en}
                      experience={job.experience_level.name_en}
                      education={job.education_level.name_en}
                      gender={job.gender}
                      payment={job.salary + " " + job.currrency}
                      desc={job.job_categories[0]?.category?.title}
                      type={job.job_type}
                      date={job.date}
                      image={job.company.img_url}
                      id={job.id}
                      saved={isSaved}
                      expired={job?.expired}
                    />
                  );
                })} 
            </>
          )}
        </div>

        <button
          className="view_button1 buttonview"
          onClick={() => {
            if (joblist === 3) {
              setjoblist(); // This line seems to be incomplete or incorrect
            } else {
              setjoblist(3);
            }
          }}
        >
          {" "}
          {joblist === 3 ? <div> View More</div> : <div>Show Less</div>}
        </button>
      </div>
    </div>
  );
}

export default JobListing;
