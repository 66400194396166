import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  savedjobs: [],
};

const FavoriteSlice = createSlice({
  name: "favoriteReducers",
  initialState,
  reducers: {
    
    savedjobsReq: (state) => {
      state.loading = true;
      state.error= null;
      state.savedjobs = [];
    },
    savedjobssucess: (state, action) => {
      state.loading = false;
      state.savedjobs = action.payload;
    },
    savedjobsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    
  },
});

export const FavoriteActions = FavoriteSlice.actions;
export default FavoriteSlice;
