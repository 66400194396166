import axios from "axios";
import { AboutUsActions } from "./AboutUsReducers";

export const getAllAboutUs = () => async (dispatch) => {
  try {
    dispatch(AboutUsActions.AboutUsRequest());
    const res = await axios.get(process.env.REACT_APP_API + "/aboutus/getAll",
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
    );
    dispatch(AboutUsActions.AboutUsSuccess(res.data.data));
  } catch (error) {
    dispatch(AboutUsActions.AboutUsFail(error));
  }
};
