import React, { useState, useEffect } from "react";

import imagehead from "../../assets/images/profile/headingc.png";
// import imagehead1 from "./../../assets/images/Company/bg1.png";
import profile from "./../../assets/images/profile/Path 786.png";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import facebook from "./../../assets/images/profile/Group 55.png";
import linked from "./../../assets/images/profile/Group 57.png";
import instagram from "./../../assets/images/profile/Group 56.png";
import icon1 from "./../../assets/images/profile/Group_451.png";
import icon2 from "./../../assets/images/profile/Group_447.png";

import icon5 from "./../../assets/images/profile/Path 998.png";
import { useDispatch, useSelector } from "react-redux";

import { IoMailOutline } from "react-icons/io5";
import { BsBrowserChrome } from "react-icons/bs";
import { FaFirefox } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import { RxActivityLog } from "react-icons/rx";
import JobListingCard from "../../Components/Profile/JobListCard";
import "./Company.css";
import { Link } from "react-router-dom";

import { useParams } from "react-router-dom";

import { getCompanyById } from "../../Redux/Company/CompanyActions";
import { BsPhone } from "react-icons/bs";

import { getsavedjobs } from "../../Redux/Favorite/FavoriteActions";
import Loader from "./../../Components/Loader/Loader";
// import navy from "../../assets/images/home/Path 195.webp";
function Company() {
  
  const dispatch = useDispatch();
  const { id } = useParams();
  const { CompanyDetails, loading1 } = useSelector((store) => store.Companies);
  const { savedjobs } = useSelector((store) => store.Favorites);

  const [CompanyDetailsData, setCompanyDetailsData] = useState([]);
  const [favoritesJobsArray, setFavoritesJobsArray] = useState([]);

  useEffect(() => {
    dispatch(getCompanyById(id));
    dispatch(getsavedjobs());
  }, [id]);

  useEffect(() => {
    if (CompanyDetails != []) {
      setCompanyDetailsData(CompanyDetails?.data);
    }
  }, [CompanyDetails]);
  const position = {
    lat: parseFloat(CompanyDetailsData?.location_latitude),
    lng: parseFloat(CompanyDetailsData?.location_longitute),
  };
  useEffect(() => {
    if (savedjobs?.data?.length > 0) {
      savedjobs?.data?.map((job) => {
        if (!favoritesJobsArray.includes(job?.job_id)) {
          setFavoritesJobsArray((favoritesJobsArray) => [
            ...favoritesJobsArray,
            job?.job_id,
          ]);
        }
      });
    }
  }, [savedjobs]);

  const BlueLoader = () => {
    return (
      <div className="blue-loader-container">
        <div className="blue-loader"></div>
      </div>
    );
  };
  if (loading1) {
    return <Loader />;
  } else {
    return (
      <div className="single-page">
        <div className="bothpic">
          <img src={profile} className="profile" />
          <img src={imagehead} className="fist-image" />
          <img
            src={
              process.env.REACT_APP_API +
              "/company/" +
              CompanyDetailsData?.img_url
            }
            className="img-profile"
          />
        </div>
        <div className="secondcont">
          <div className="title">
            <div className="title-first">{CompanyDetailsData?.full_name}</div>

            <div className="set-linked">
              <a
                href={CompanyDetailsData?.facebook}
                className="linked"
                target="_blank"
              >
                <img src={facebook} className="linked" />
              </a>
              <a
                href={CompanyDetailsData?.instagram} 
                className="linked"
                target="_blank"
              >
                <img src={instagram} className="linked" />
              </a>

              <a
                href={CompanyDetailsData?.linked_in}
                className="linked"
                target="_blank"
              >
                <img src={linked} className="linked" />
              </a>
              <Link
                to={CompanyDetailsData?.website}
                className="linked"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFirefox size={30} />
              </Link>
            </div>
          </div>
          <div className="setofdetails">
            <div className="datials">
              <a href={`tel:${CompanyDetailsData?.phone_number}`}>
                <div className="item">
                  <BsPhone /> {CompanyDetailsData?.phone_number}{" "}
                </div>
              </a>
            </div>
            <div className="datials">
              <a href={`mailto:${CompanyDetailsData?.email}`}>
                <div className="item">
                  <IoMailOutline /> {CompanyDetailsData?.email}
                </div>
              </a>
            </div>
            <div className="datials">
              <div className="item">
                <CiLocationOn />{" "}
                {CompanyDetailsData?.district?.name_en
                  ? CompanyDetailsData?.district?.name_en
                  : "Location not available"}
              </div>
            </div>

            <div className="datials">
              <div className="item">
                <RxActivityLog />{" "}
                {CompanyDetailsData &&
                  CompanyDetailsData.company_activities &&
                  CompanyDetailsData.company_activities[0] &&
                  CompanyDetailsData.company_activities[0].activity &&
                  CompanyDetailsData.company_activities[0].activity.title}
              </div>
            </div>
          </div>

          <div className="title-first">About the company</div>
          <div className="description">{CompanyDetailsData?.description}</div>
          <div className="title-first">Posted Jobs</div>

          <div className="jobs_container">
            {CompanyDetails?.jobs?.length == 0 && (
              <div className="description">No Posted Job</div>
            )}
            {CompanyDetails?.jobs &&
              CompanyDetails?.jobs.map((job) => {
                let isSaved = false;
                if (favoritesJobsArray.includes(job.id)) {
                  isSaved = true;
                }
                return (
                  <JobListingCard
                    position={job.title_en}
                    name={job.company.full_name}
                    location={job.district.name_en}
                    experience={job.experience_level.name_en}
                    education={job.education_level.name_en}
                    gender={job.gender}
                    payment={job.currrency}
                    desc={job.description_en}
                    type={job.job_type}
                    date={job.date}
                    image={job.company.img_url}
                    id={job.id}
                    saved={isSaved}
                    expired={job.expired}
                  />
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default Company;
