import axios from "axios";
import { CvActions } from "./CVBuilderReducers";

export const CreateCv = (Cv) => async (dispatch) => {
  try {
    dispatch(CvActions.CVRequest());
    const { data } = await axios.post(process.env.REACT_APP_API + "/cv", Cv);
    dispatch(CvActions.CVSuccess(data));
  } catch (error) {
    dispatch(CvActions.CVFail(error));
  }
};
