import React, { useEffect } from "react";
import heading from "../../assets/images/aboutus/Path 489.svg";
import "./SavedjobList.css";
import SavedJobs from "../../Components/Profile/SavedJobs";
import { useDispatch, useSelector } from "react-redux";
import { getsavedjobs } from "../../Redux/Favorite/FavoriteActions";
function SavedjobList() {
  const dispatch = useDispatch();
  const { savedjobs } = useSelector((store) => store.Favorites);
  useEffect(() => {
    dispatch(getsavedjobs());
  }, []);

  return (
    <div className="Main_job_listss">
      <div className="Aboutus_heading">
        <div className="Aboutus_heading_img">
          <img src={heading} alt="heading" className="heading" />
        </div>

        <div className="Aboutus_heading_text">
          <div className="Aboutus_heading_text_title_2"> Saved Jobs</div>
          <div className="Aboutus_heading_text_desc">
            {" "}
            <a href="/">Home /</a> <span className="head_span">Saved Jobs</span>
          </div>{" "}
          {/* <div className="Aboutus_heading_text_desc">
            {" "}
            Home/ <span className="head_span">Companies Registration</span>
          </div> */}
        </div>
      </div>
      <div className="Main_job_listss_inner">
        {savedjobs?.data?.length == 0 && (
          <div className="nosavedjob">
            No Saved jobs yet,please visit the jobs page
          </div>
        )}
        <div className="jobs_container">
          {savedjobs?.data?.map((job) => (
            <SavedJobs
              position={job.job.title_en}
              date={job.job.date}
              name={job.job.company.full_name}
              location={job.job.district.name_en}
              experience={job.job.experience_level.name_en}
              education={job.job.education_level.name_en}
              gender={job.job.gender}
              payment={job.job.currrency}
              type={job.job.job_type}
              id={job.id}
              image={job.job.company.img_url}
              jobId={job?.job_id}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default SavedjobList;
