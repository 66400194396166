import axios from "axios";
import { UserActions } from "./UserReducers";

export const getmyinfo = () => async (dispatch) => {
  try {
    dispatch(UserActions.infoReq());
    const token = localStorage.getItem("token");
    const res = await axios.get(process.env.REACT_APP_API + "/user/myInfo", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(UserActions.infosucess(res.data.data));
  } catch (error) {
    dispatch(UserActions.infoFail(error));
  }
};
export const editmyinfo = (data) => async (dispatch) => {
  try {
    dispatch(UserActions.infoeditReq());
    const token = localStorage.getItem("token");
    const res = await axios.put(process.env.REACT_APP_API + "/user/", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(UserActions.infoeditsucess(res.data.data));
  } catch (error) {
    dispatch(UserActions.infoeditFail(error));
  }
};
export const editmyimg = (imgs) => async (dispatch) => {
  try {
    dispatch(UserActions.imgeditreuqest());
    const token = localStorage.getItem("token");

    const res = await axios.put(
      process.env.REACT_APP_API + "/user/assigne",
      imgs,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(UserActions.imgeditsucess(res.data.data));
  } catch (error) {
    dispatch(UserActions.imgeditfail(error));
  }
};

export const getcontactus =
  (fullname, email, number, message) => async (dispatch) => {
    try {
      dispatch(UserActions.contactusReq);
      const token = localStorage.getItem("token");
      const res = await axios.post(
        process.env.REACT_APP_API + "/contact",

        {
          full_name: fullname,
          email: email,
          phone_number: number,
          message: message,
        }
      );
      dispatch(
        UserActions.contactussucess(
          "Thank you for reaching out to us. We appreciate your message and will respond to you shortly."
        )
      );
    } catch (error) {
      dispatch(UserActions.contactusFail(error));
    }
  };
