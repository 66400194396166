import axios from "axios";
import { CompanyActions } from "./CompanyReducers";

export const getAllCompanies = (activity, search) => async (dispatch) => {
  try {
    dispatch(CompanyActions.CompanyRequest());
    const res = await axios.get(
      process.env.REACT_APP_API +
        "/company/getAll?" +
        (activity.length > 0 ? "activity_id=[" + activity + "]" : "") +
        (search ? "&search=" + search : "")
    );
    dispatch(CompanyActions.CompanySuccess(res.data.data));
  } catch (error) {
    dispatch(CompanyActions.CompanyFail(error));
  }
};

export const getCompanyById = (id) => async (dispatch) => {
  try {
    dispatch(CompanyActions.CompanyDetailsRequest());
    const res = await axios.get(
      process.env.REACT_APP_API + "/company/getOne/" + id
    );
    dispatch(CompanyActions.CompanyDetailsSuccess(res.data));
  } catch (error) {
    dispatch(CompanyActions.CompanyDetailsFail(error));
  }
};
