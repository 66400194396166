import React, { useState, useEffect } from "react";
import "./CompaniesCard.css";
import { useNavigate } from "react-router-dom";

function CompaniesCard(props) {
  const navigate = useNavigate();
  return (
    <div className="Main_companies_div" onClick={() => navigate("/company/"+props.companyId )}>
      <div className="companies_card">
        <div className="companies_card_img_cont">
          <img
            src={process.env.REACT_APP_API + "/company/" + props.logo}
            alt="companies"
            className="companies_card_img"
          />
        </div>
        <div className="companies_card_text_cont">
          <div className="companies_card_name">{props.name}</div>
          <div className="companies_card_category">{props.category}</div>
          <div className="companies_card_text"> {props.district}</div>
        </div>
      </div>
    </div>
  );
}

export default CompaniesCard;
