import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  Company: [],
  loading1: false,
  error1: null,
  CompanyDetails: [],
};

const CompanySlice = createSlice({
  name: "CompanyReducers",
  initialState,
  reducers: {
    CompanyRequest: (state) => {
      state.loading = true;
    },
    CompanySuccess: (state, action) => {
      state.loading = false;
      state.Company = action.payload;
    },
    CompanyFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    CompanyDetailsRequest: (state) => {
      state.loading1 = true;
    },
    CompanyDetailsSuccess: (state, action) => {
      state.loading1 = false;
      state.CompanyDetails = action.payload;
    },
    CompanyDetailsFail: (state, action) => {
      state.loading1 = false;
      state.error1 = action.payload;
    },
    
  },
});

export const CompanyActions = CompanySlice.actions;
export default CompanySlice;
