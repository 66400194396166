import axios from "axios";
import { JobsAction } from "./JobsReducers";

export const getJobs =
  ({
    district_id,
    expertise_id,
    job_type,
    education_level_id,
    experience_level_id,
    search,
  }) =>
  async (dispatch) => {
    dispatch(JobsAction.jobsreq());
    let url;
    url = process.env.REACT_APP_API + "/job?";

    if (district_id.length > 0){
      if(district_id.length===1)
        url += "district_id=" + district_id + "&";
      else
        url += "district_id=[" + district_id + "]&";
    } 

    if (expertise_id.length > 0) {
      
      if(expertise_id.length===1)
        url += "category_id=" + expertise_id + "&";
      else
        url += "category_id=[" + expertise_id + "]&";
      
    } 
    
    if (job_type.length > 0) {
      if (job_type.length === 1) url += "job_type=" + job_type + "&";
      else {
        const decodedArray = job_type.map((value) => `"` + value + `"`);
        url += "job_type=[" + decodedArray + "]&";
      }
    }

    if (education_level_id.length > 0){
      if(education_level_id.length===1)
        url += "education_level_id=" + education_level_id + "&";
      else
      url += "education_level_id=[" + education_level_id + "]&";
    }

    if (experience_level_id.length > 0){
      if(experience_level_id.length===1)
        url += "experience_level_id=" + experience_level_id + "&";
      else
      url += "experience_level_id=[" + experience_level_id + "]&";
    }

    if (search) url += "search=" + search + "&";
    try {
      let response;
      const token = localStorage.getItem("token");
      response = await axios.get(url, { Authorization: "Bearer " + token });
      const firstThreeJobs = response.data.data;

      dispatch(JobsAction.jobssucess(firstThreeJobs));
    } catch (error) {
      dispatch(JobsAction.jobsfail(error.message));
    }
  };
export const getJobs2 = (search) => async (dispatch) => {
  dispatch(JobsAction.jobsreq());
  let url;
  url =
    process.env.REACT_APP_API + "/job?" + (search ? "search=" + search : "");
  try {
    let response;
    const token = localStorage.getItem("token");
    response = await axios.get(url, { Authorization: "Bearer " + token });
    dispatch(JobsAction.jobssucess(response.data.data));
  } catch (error) {
    dispatch(JobsAction.jobsfail(error.message));
  }
};
export const getJobById = (id) => async (dispatch) => {
  dispatch(JobsAction.jobidreq());
  let url;
  url = process.env.REACT_APP_API + "/job/" + id;
  try {
    let response;
    const token = localStorage.getItem("token");
    response = await axios.get(url, { Authorization: "Bearer " + token });
    dispatch(JobsAction.jobidsucess(response.data));
  } catch (error) {
    dispatch(JobsAction.jobidfail(error.message));
  }
};

export const getifCheck = (id) => async (dispatch) => {
  dispatch(JobsAction.isApplyreq());
  let url;
  url = process.env.REACT_APP_API + "/apply/check/" + id;
  try {
    let response;
    const token = localStorage.getItem("token");
    response = await axios.get(url, { Authorization: "Bearer " + token });
    dispatch(JobsAction.isApplysucess(response.data));
  } catch (error) {
    dispatch(JobsAction.isApplyfail(error.message));
  }
};
export const applyjob = (job_id) => async (dispatch) => {
  try {
    dispatch(JobsAction.applyreq());
    const token = localStorage.getItem("token");
    const res = await axios.post(
      process.env.REACT_APP_API + "/apply",

      {
        job_id: job_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    dispatch(JobsAction.applysucess(res.data.data));
  } catch (error) {
    dispatch(JobsAction.applyfail(error.message));
  }
};

export const getapplylist = () => async (dispatch) => {
  try {
    dispatch(JobsAction.applylistreq());
    const token = localStorage.getItem("token");
    const res = await axios.get(process.env.REACT_APP_API + "/apply", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(JobsAction.applylistsucess(res.data.data));
  } catch (error) {
    dispatch(JobsAction.applylistfail(error.message));
  }
};
