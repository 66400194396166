import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  Activity: [],

  Category: [],
  loading1: false,
  error1: null,
};

const ActivitySlice = createSlice({
  name: "ActivityReducers",
  initialState,
  reducers: {
    ActivityRequest: (state) => {
      state.loading = true;
    },
    ActivitySuccess: (state, action) => {
      state.loading = false;
      state.Activity = action.payload;
    },
    ActivityFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    CategoryRequest: (state) => {
      state.loading1 = true;
    },
    CategorySuccess: (state, action) => {
      state.loading1 = false;
      state.Category = action.payload;
    },
    CategoryFail: (state, action) => {
      state.loading1 = false;
      state.error1 = action.payload;
    },
    
  },
});

export const ActivityActions = ActivitySlice.actions;
export default ActivitySlice;
