import axios from "axios";
import { FavoriteActions } from "./FavoriteReducers";




export const getsavedjobs = () => async (dispatch) => {
  try {
    dispatch(FavoriteActions.savedjobsReq());
    const token = localStorage.getItem("token");
    const res = await axios.get(process.env.REACT_APP_API + "/favorite", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch(FavoriteActions.savedjobssucess(res.data));
  } catch (error) {
    dispatch(FavoriteActions.savedjobsFail(error));
  }
};

export const deletesavedjobs = (id) => async (dispatch) => {
  try {
    dispatch(FavoriteActions.savedjobsReq());
    const token = localStorage.getItem("token");
    const res = await axios.delete(
      process.env.REACT_APP_API + "/favorite/" + id,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(FavoriteActions.savedjobssucess(res.data));
  } catch (error) {
    dispatch(FavoriteActions.savedjobsFail(error));
  }
};
export const addsavedjobs = (job_id) => async (dispatch) => {
  try {
    dispatch(FavoriteActions.savedjobsReq());
    const token = localStorage.getItem("token");
    const res = await axios.post(
      process.env.REACT_APP_API + "/favorite",
      {
        job_id: job_id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      
    );
    dispatch(FavoriteActions.savedjobssucess(res.data.data));
  } catch (error) {
    dispatch(FavoriteActions.savedjobsFail(error));
  }
};
