import React, { useState , useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./QnA.scss";
import heading from "../../assets/images/profile/Path 489.png";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { getAllQuestionsAnswers } from "../../Redux/QuestionsAnswers/QuestionsAnswersActions";

function QnA() {
  const [activeIndex, setActiveIndex] = useState(null);
  const dispatch = useDispatch();
  const { questionsAnswers, loading } = useSelector((store) => store.QuestionsAnswers);
  const [questionsAnswersData, setQuestionsAnswersData] = useState([]);

  const toggleAccordion = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };


  useEffect(() => {
    dispatch(getAllQuestionsAnswers());
  }, []);

  useEffect(() => {
    if (questionsAnswers) {
      setQuestionsAnswersData(questionsAnswers);
    }
  }, [questionsAnswers]);


  return (
    <div className="Main_qna">
      <div className="Aboutus_heading">
        <div className="Aboutus_heading_img">
          <img src={heading} alt="heading" className="heading" />
        </div>

        <div className="Aboutus_heading_text">
          <div className="Aboutus_heading_text_title"> Q&A</div>
          <div className="Aboutus_heading_text_desc">
            {" "}
            <a href="/">Home  /</a><span className="head_span">Q&A</span>
          </div>
        </div>
      </div>
      <div className="qna_cont">
        <div className="app">
          <div className="app__head">
            <h1>Questions and answers about Mehnati</h1>
          </div>
          <div className="app__content">
            <div className="accordion-container">
              {questionsAnswersData.map((item, index) => (
                <div
                  className={`accordion ${index === activeIndex ? "is-open" : ""}`}
                  key={index}
                >
                  <header
                    className="accordion__header"
                    onClick={() => toggleAccordion(index)}
                  >
                    <p>
                      <button>
                        <span className="accordion__title">{item.question}</span>
                        <div className="accordion__icon">
                          {index === activeIndex ? (
                            <AiOutlineMinus size={24} />

                          ) : (
                            <AiOutlinePlus size={24} />
                           
                          )}
                        </div>
                      </button>
                    </p>
                  </header>
                  {index === activeIndex && (
                    <div className="accordion__content">
                      <div className="accordion__inner">
                        {item.answer}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}

export default QnA;




