import axios from "axios";
import { ActivityActions } from "./ActivityReducers";

export const getAllActivities = () => async (dispatch) => {
  try {
    dispatch(ActivityActions.ActivityRequest());
    const res = await axios.get(process.env.REACT_APP_API + "/activity/getAll");
    dispatch(ActivityActions.ActivitySuccess(res.data.data));
    
  } catch (error) {
    dispatch(ActivityActions.ActivityFail(error));
  }
};

export const getAllCategory = () => async (dispatch) => {
  try {
    dispatch(ActivityActions.CategoryRequest());
    const res = await axios.get(process.env.REACT_APP_API + "/category/getAll");
    dispatch(ActivityActions.CategorySuccess(res.data.data));
    
  } catch (error) {
    dispatch(ActivityActions.CategoryFail(error));
  }
};
