import { combineReducers } from "redux";

import CvBuilderSlice from "./CVBuilder/CVBuilderReducers";
import AuthSlice from "./Auth/AuthReducers";
import UserSlice from "./User/UserReducers";
import JobsSlice from "./jobs/JobsReducers";
import successStoriesSlice from "./SuccessStories/SuccessStoriesReducers";
import CompanyStatsSlice from "./CompanyStats/CompanyStatsReducers";
import FavoriteSlice from "./Favorite/FavoriteReducers";
import ActivitySlice from "./Activity/ActivityReducers";
import CompanySlice from "./Company/CompanyReducers";
import AboutUsSlice from "./AboutUs/AboutUsReducers";
import QuestionsAnswersSlice from "./QuestionsAnswers/QuestionsAnswersReducers";

const RootReducers = combineReducers({
  CvBuilder: CvBuilderSlice.reducer,
  Auth: AuthSlice.reducer,
  User: UserSlice.reducer,
  Jobs: JobsSlice.reducer,
  SuccessStories: successStoriesSlice.reducer,
  CompanyStatss: CompanyStatsSlice.reducer,
  Favorites: FavoriteSlice.reducer,
  Activities: ActivitySlice.reducer,
  Companies: CompanySlice.reducer,
  AboutUs:AboutUsSlice.reducer,
  QuestionsAnswers:QuestionsAnswersSlice.reducer,
});

export default RootReducers;
