import React, { useState, useEffect } from "react";
import "./ChangePassword.css";
import heading from "../../assets/images/CompleteProfile/Path 489.png";
import { FiCheck } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { ChangePassword1 } from "../../Redux/Auth/AuthActions";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

function ChangePassword() {
  const [old, setold] = useState("");
  const [password, setpassword] = useState("");
  const [confirm, setconfirm] = useState("");
  const [forgetPasswordError, setForgetPasswordError] = useState("");
  const [forgetPasswordAction, setForgetPasswordAction] = useState(false);
  const dispatch = useDispatch();
  const { changepassword, error, loading } = useSelector((store) => store.Auth);
  const emailRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&*()_+{}[\]|\-<>?])[A-Za-z\d@#$%^&*()_+{}[\]|\-<>?]{8,}$/;
  const [disabled, setdisabled] = useState(true);
  useEffect(() => {
    setForgetPasswordError("");
  }, []);
  const handleLogin = (e) => {
    e.preventDefault();

    dispatch(ChangePassword1(old, password));
    setForgetPasswordAction(true);
  };

  useEffect(() => {
    let actionDispatched = false;
    if (forgetPasswordAction && !loading) {
      if (!actionDispatched && !error) {
        NotificationManager.success(
          changepassword.message,
        );
        actionDispatched = true;
      } else if (error) {
        NotificationManager.error(error);
      }
      setForgetPasswordAction(false);
    }
  }, [loading, changepassword, error, forgetPasswordAction]);
  
  const [showpassword, setshowpassword] = useState(false);
  const [showpassword1, setshowpassword1] = useState(false);
  const handlePaste = (event) => {
    event.preventDefault();

    // Access the clipboard data
    const clipboardData = event.clipboardData || window.clipboardData;

    // Get the pasted text from the clipboard
    const pastedText = clipboardData.getData("text/plain");

    // Do something with the pasted text (e.g., display an alert)
  };
  useEffect(() => {
    if (
      old === "" ||
      password === "" ||
      confirm === "" ||
      password !== confirm ||
      !password.match(emailRegex)
    ) {
      setdisabled(true);
    } else {
      setdisabled(false);
    }
  }, [old, password, confirm]);

  return (
    <div className="Main_login">
      <div className="Aboutus_heading">
        <div className="Aboutus_heading_img">
          <img src={heading} alt="heading" className="heading" />
        </div>

        <div className="Aboutus_heading_text">
          <div className="Aboutus_heading_text_title">
            {" "}
            Technicians Change Password
          </div>
          <div className="Aboutus_heading_text_desc">
            {" "}
            <a href="/">Home /</a>
            <span className="head_span">Change Password</span>
          </div>
        </div>
      </div>
      <div className="login_form_cont">
        <div className="login-cont">
          <NotificationContainer />
          <form>
            <div className="signup_data">
              <div className="null_container">
                {old !== "" && <FiCheck size={30} color={"#A2BF83"} />}
              </div>
              <input
                type="text"
                placeholder="Current Password"
                // className="input_complete_1"
                className={
                  old !== "" ? "input_complete_1_check" : "input_complete_1"
                }
                required
                onChange={(e) => setold(e.target.value)}
                onPaste={handlePaste}
              />
            </div>

            <div className="signup_data">
              <div className="null_container">
                {password !== "" && <FiCheck size={30} color={"#A2BF83"} />}
              </div>
              <input
                type={showpassword1 ? "text" : "password"}
                placeholder="New Password"
                // className="input_complete_1"
                className={
                  password !== "" && password === confirm
                    ? "input_complete_1_check"
                    : "input_complete_1"
                }
                required
                onChange={(e) => setpassword(e.target.value)}
                onPaste={handlePaste}
              />
              {!showpassword1 && (
                <div
                  className="eye"
                  onClick={() => setshowpassword1(!showpassword1)}
                >
                  <AiFillEye size={30} />
                </div>
              )}
              {showpassword1 && (
                <div
                  className="eye"
                  onClick={() => setshowpassword1(!showpassword1)}
                >
                  <AiFillEyeInvisible size={30} />
                </div>
              )}
            </div>

            <div className="signup_data">
              <div className="null_container">
                {confirm !== "" && <FiCheck size={30} color={"#A2BF83"} />}
              </div>
              <input
                type={showpassword ? "text" : "password"}
                placeholder="Confirm Password"
                // className="input_complete_1"
                className={
                  password !== "" && password === confirm
                    ? "input_complete_1_check"
                    : "input_complete_1"
                }
                required
                onChange={(e) => setconfirm(e.target.value)}
                onPaste={handlePaste}
              />
              {!showpassword && (
                <div
                  className="eye"
                  onClick={() => setshowpassword(!showpassword)}
                >
                  <AiFillEye size={30} />
                </div>
              )}
              {showpassword && (
                <div
                  className="eye"
                  onClick={() => setshowpassword(!showpassword)}
                >
                  <AiFillEyeInvisible size={30} />
                </div>
              )}
            </div>
          </form>
          <div className="dateofbirth1">
            .At least 8 characters in length<br></br>.At least one uppercase
            letter<br></br>.At least one lowercase letter
            <br></br>.At least one digit.<br></br>.At least one special
            character
          </div>

          {forgetPasswordError && (
            <p className="error">
              {" "}
              {forgetPasswordError.response.data.message}{" "}
            </p>
          )}
          <div className="button_container" onClick={handleLogin}>
            <button
              className={disabled ? "nextbutton1" : "nextbutton"}
              type="submit"
              disabled={disabled}
            >
              {" "}
              Change Password{" "}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
