import React, { useState, useEffect } from "react";
import "./SavedJobs.css";
import { VscLocation } from "react-icons/vsc";
import { ImStack } from "react-icons/im";
import { SlGraduation } from "react-icons/sl";
import { TbCurrencyDollar } from "react-icons/tb";
import { BsGenderAmbiguous } from "react-icons/bs";
import { BsBookmarkFill } from "react-icons/bs";
import { BsBookmark } from "react-icons/bs";
import { deletesavedjobs } from "../../Redux/Favorite/FavoriteActions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
function SavedJobs(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [saved, setsaved] = useState(true);
  const handlesaved = () => {
    setsaved(!saved);
    dispatch(deletesavedjobs(props.jobId));
  };
  return (
    <div className="SavedJobsCard_container">
      <div>
        {" "}
        <img
          src={process.env.REACT_APP_API + "/company/" + props.image}
          alt="Image"
          className="Libanlogo"
          onClick={() => navigate(`/jobdetails/${props.jobId}`)}
        />{" "}
      </div>
      <div
        className="MARKETING_CONT1"
        onClick={() => navigate(`/jobdetails/${props.jobId}`)}
      >
        <div className="MARK_TITLE">{props.position}</div>
        <div className="MARK_NAME">{props.name} </div>
        <div className="info_card_cont">
          <div className="MARK_EXPERIENCE">
            <VscLocation size={25} />
            &nbsp; {props.location}
          </div>
          <div className="MARK_EXPERIENCE">
            {" "}
            <ImStack size={20} /> &nbsp; {props.experience}
          </div>
          <div className="MARK_EXPERIENCE">
            <SlGraduation size={20} /> &nbsp; {props.education}
          </div>
          <div className="MARK_EXPERIENCE">
            <BsGenderAmbiguous size={20} /> &nbsp; {props.gender}
          </div>
          <div className="MARK_EXPERIENCE">
            <TbCurrencyDollar size={20} /> &nbsp; {props.payment}
          </div>
        </div>
      </div>

      <div className="MARKETING_CONT2">
        <div onClick={handlesaved}>
          {saved ? (
            <div className="BOOKMARK_ICON">
              {" "}
              <BsBookmarkFill size={25} />{" "}
            </div>
          ) : (
            <div className="BOOKMARK_ICON">
              {" "}
              <BsBookmark size={25} />{" "}
            </div>
          )}
        </div>
        <div className="Desc">{props.desc}</div>
        <button className="CONT2_BUTTON"> {props.type} </button>
        <div className="POSTDATE"> Post Date: {props.date} </div>
      </div>
    </div>
  );
}

export default SavedJobs;
