import React, { useEffect, useState } from "react";
import "./AppliedJobsList.css";
import heading from "../../assets/images/CompleteProfile/Path 489.png";
import Applied_Jobs from "../../Components/Profile/Applied_jobs";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader/Loader";
import { getapplylist } from "../../Redux/jobs/JobsActions";
import { useNavigate } from "react-router-dom";

function AppliedJobsList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { applylist } = useSelector((store) => store.Jobs);
  const { loadingapply } = useSelector((state) => state.Jobs);
  useEffect(() => {
    dispatch(getapplylist());
  }, []);

  return (
    <div className="applied_list">
      <div className="Aboutus_heading">
        <div className="Aboutus_heading_img">
          <img src={heading} alt="heading" className="heading" />
        </div>

        <div className="Aboutus_heading_text">
          <div className="Aboutus_heading_text_title"> Applied Jobs List</div>
          <div className="Aboutus_heading_text_desc">
            {" "}
            <a href="/">Home /</a>{" "}
            <span className="head_span">Applied Jobs</span>
          </div>
        </div>
      </div>
      <div className="applied_list_container">
        {loadingapply ? (
          <div className="loader_container">
            <Loader />
          </div>
        ) : (
          <>
            {applylist?.map((job) => (
              <Applied_Jobs
                key={job.id}
                position={job.job.title_en}
                location={job.job.district.name_en}
                createdDate={job.createdAt}
                buttontext={job.status}
                id={job?.job?.id}
                reason={job.reason}
                className="HIRE"
                image={job.job.company.img_url}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default AppliedJobsList;
