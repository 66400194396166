import React, { useEffect, useState } from "react";
import "./SucessStorie.css";
function SucessStories(props) {
  const [statusClass, setstatusClass] = useState("");
  const changeStatusClass = () => {
    let status = props.status.toLowerCase();

    if (status === "success story") {
      setstatusClass("pinkcolor");
    } else if (status === "positive feedback") {
      setstatusClass("greencolor");
    } else if (status === "internship") {
      setstatusClass("purplecolor");
    }
  };
  useEffect(() => {
    changeStatusClass();
  }, [props.status]);

  return (
    <div className="Main_stories_divs">
      <div className="stories_cards">
        <div className="stories_card_img_cont">
          <img
            src={process.env.REACT_APP_API + "/success_story/" + props.img}
            alt="stories"
            className="stories_card_img"
          />
        </div>
        <div className="stories_card_text_cont">
          {/* <div className="stories_card_status">{props.status}</div> */}
          <div className={`stories_card_status ${statusClass}`}>
            {props.status}
          </div>
          <div className="stories_card_date">{props.date}</div>
          <div className="stories_card_name">{props.name}</div>
          <div className="stories_card_text"> {props.text}</div>
          <div className="stories_card_btn"> {props.position}</div>
        </div>
      </div>
    </div>
  );
}

export default SucessStories;
