// src/components/TrackedLink.js
import React from 'react';
import { logEvent, initGA } from '../analytics';

const TrackedLink = ({ url, children }) => {
  const handleClick = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    
    try {
      const hostname = new URL(url).hostname;
      initGA(hostname);
      logEvent('Link Click', `Navigate to ${hostname}`, `User clicked link to ${hostname}`);

      // Delay the navigation to ensure the event is logged
      setTimeout(() => {
        window.open(url, '_blank', 'noopener noreferrer');
      }, 500);
    } catch (error) {
      console.error('Invalid URL:', url, error);
    }
  };

  return (
    <a href={url} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
      {children}
    </a>
  );
};

export default TrackedLink;
