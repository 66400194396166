import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  loading11: false,
  error: null,
  jobs: [],
  jobid: [],
  apply: [],
  isApply: [],

  loadingapply: false,
};

const JobsSlice = createSlice({
  name: "jobReducers",
  initialState,
  reducers: {
    jobsreq: (state) => {
      state.loading = true;
    },
    jobssucess: (state, action) => {
      state.loading = false;
      state.jobs = action.payload;
    },
    jobsfail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    jobidreq: (state) => {
      state.loading = true;
    },
    jobidsucess: (state, action) => {
      state.loading = false;
      state.jobid = action.payload;
    },
    jobidfail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    applyreq: (state) => {
      state.loading11 = true;
    },
    applysucess: (state, action) => {
      state.loading11 = false;
      state.apply = action.payload;
      state.isApply = {data: true}
    },
    applyfail: (state, action) => {
      state.loading11 = false;
      state.error = action.payload;
    },
    applylistreq: (state) => {
      state.loadingapply = true;
    },
    applylistsucess: (state, action) => {
      state.loadingapply = false;
      state.applylist = action.payload;
    },
    applylistfail: (state, action) => {
      state.loadingapply = false;
      state.error = action.payload;
    },

    isApplyreq: (state) => {},
    isApplysucess: (state, action) => {
      state.isApply = action.payload;
    },
    isApplyfail: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const JobsAction = JobsSlice.actions;
export default JobsSlice;
