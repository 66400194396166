import axios from "axios";
import { CompanyStatsActions } from "./CompanyStatsReducers";

export const CompanyStats = () => async (dispatch) => {
  try {
    dispatch(CompanyStatsActions.CompanyStatsRequest());
    const res = await axios.get(process.env.REACT_APP_API + "/company/top10");
    dispatch(CompanyStatsActions.CompanyStatsSuccess(res.data.data));
  } catch (error) {
    dispatch(CompanyStatsActions.CompanyStatsFail(error.message));
  }
};

export const getstatistics = () => async (dispatch) => {
  try {
    dispatch(CompanyStatsActions.statisticsRequest());
    const res = await axios.get(process.env.REACT_APP_API + "/");
    dispatch(CompanyStatsActions.statisticsSuccess(res.data.data));
  } catch (error) {
    dispatch(CompanyStatsActions.statisticsFail(error.message));
  }
};
