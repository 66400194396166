import React, { useEffect, useState } from "react";
import "./SuccessStories.css";
import heading from "../../assets/images/CompleteProfile/Path 489.png";
import { useSelector, useDispatch } from "react-redux";
import { getSuccessStories } from "../../Redux/SuccessStories/SuccessStoriesActions";
import SucessStories from "../../Components/SucessStories/SucessStories";
import { Link } from "react-router-dom";
function SuccessStories() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSuccessStories());
  }, []);
  const { successStories } = useSelector((store) => store.SuccessStories);
  return (
    <div className="Main_success_page">
      <div className="Aboutus_heading">
        <div className="Aboutus_heading_img">
          <img src={heading} alt="heading" className="heading" />
        </div>

        <div className="Aboutus_heading_text">
          <div className="Aboutus_heading_text_title"> Success Stories</div>
          <div className="Aboutus_heading_text_desc">
            {" "}
            <Link to="/"> Home </Link> /{" "}
            <span className="head_span">Success Stories</span>
          </div>
        </div>
      </div>
      <div className="success_stories">
        <div className="success_stories_heading">Success Stories</div>
        <div className="success_container">
          {successStories.map((item) => (
            <SucessStories
              img={item.img_url}
              status={item.status}
              date={item.time}
              name={item.full_name}
              text={item.information}
              position={item.title}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
export default SuccessStories;
