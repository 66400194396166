import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./AboutUs.css";
import heading from "../../assets/images/aboutus/Path 489.svg";

import funded from "../../assets/images/aboutus/logos fund 1.png";
import imp from "../../assets/images/aboutus/implemented by.png";
import Age from "../../assets/images/aboutus/pngs-85.png";
import com from "../../assets/images/aboutus/pngs-84.png";
import { getAllAboutUs } from "../../Redux/AboutUs/AboutUsActions";
import imp1 from "../../assets/images/aboutus/2019-logo-iecd-officiel-130.png";
import Age1 from "../../assets/images/aboutus/Giz-logo.jpg";
import com1 from "../../assets/images/aboutus/sda-logo.png";
import com11 from "../../assets/images/aboutus/Screenshot 2023-08-17125847.png";
function AboutUs() {
  const dispatch = useDispatch();
  const { aboutUs, loading } = useSelector((store) => store.AboutUs);
  const [aboutUsData, setAboutUsData] = useState([]);

  const redirectToNewPage = (link) => {
    window.location.href = link;
  };
  // consol

  useEffect(() => {
    dispatch(getAllAboutUs());
  }, []);

  useEffect(() => {
    if (aboutUs) {
      setAboutUsData(aboutUs);
    }
  }, [aboutUs]);

  return (
    <div className="Main_aboutus">
      <div className="Aboutus_heading">
        <div className="Aboutus_heading_img">
          <img src={heading} alt="heading" className="heading" />
        </div>
        <div className="Aboutus_heading_text">
          <div className="Aboutus_heading_text_title"> About Us</div>
          <div className="Aboutus_heading_text_desc">
            {" "}
            <a href="/">Home /</a> <span className="head_span">About Us</span>
          </div>
        </div>
      </div>
      <div className="Aboutus_main">
        {aboutUsData.map((about, index) => {
          if (index % 2 === 0) {
            return (
              <div className="Aboutus_div marginss">
                <div className="Aboutus_div_img">
                  <img
                    src={
                      process.env.REACT_APP_API + "/about_us/" + about?.img_url
                    }
                    alt="mehnati"
                    className="mehnati"
                  />
                </div>
                <div className="Aboutus_div_text">
                  <div className="Aboutus_div_text_title">{about?.title}</div>
                  <div className="Aboutus_div_text_desc">
                    {about?.description}
                  </div>
                  <div className="Aboutus_div_button">
                    <button
                      className="Aboutus_div_button_btn"
                      onClick={() => redirectToNewPage(about?.link)}
                    >
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div className="Aboutus_div2">
                <div className="Aboutus_div_text">
                  <div className="Aboutus_div_text_title">{about?.title}</div>
                  <div className="Aboutus_div_text_desc">
                    {about?.description}
                  </div>
                  <div className="Aboutus_div_button">
                    <button
                      className="Aboutus_div_button_btn"
                      onClick={() => redirectToNewPage(about?.link)}
                    >
                      Learn More
                    </button>
                  </div>
                </div>

                <div className="Aboutus_div_img">
                  <img
                    src={
                      process.env.REACT_APP_API + "/about_us/" + about?.img_url
                    }
                    alt="mehnati"
                    className="mehnati"
                  />
                </div>
              </div>
            );
          }
        })}
      </div>
      <div className="divider_about" />
      <div className="Aboutus_footer">
        <div className="Aboutus_footer_title"> Our Sponsors </div>
        <div className="Aboutus_footer_sponsors11">
          <div className="Aboutus_footer_sponsors">
            <div className="titleaboutus"> Project funded by:</div>
            <div className="image-contcont1">
              <img src={com} alt="funded" className="funded" />
            </div>
          </div>
          <div className="Aboutus_footer_sponsors">
            <div className="titleaboutus">Project implemented by:</div>

            <div className="image-contcont1">
              <img src={Age} alt="funded" className="funded111" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
