import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  questionsAnswers: [],
};

const QuestionsAnswersSlice = createSlice({
  name: "QuestionsAnswersReducers",
  initialState,
  reducers: {
    QuestionsAnswersRequest: (state) => {
      state.loading = true;
    },
    QuestionsAnswersSuccess: (state, action) => {
      state.loading = false;
      state.questionsAnswers = action.payload;
    },
    QuestionsAnswersFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    
  },
});

export const QuestionsAnswersActions = QuestionsAnswersSlice.actions;
export default QuestionsAnswersSlice;
