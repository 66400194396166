import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  Cv: [],
};

const CvBuilderSlice = createSlice({
  name: "CvBuilderReducers",
  initialState,
  reducers: {
    CVRequest: (state) => {
      state.loading = true;
    },
    CVSuccess: (state, action) => {
      state.loading = false;
      state.Cv = action.payload;
    },
    CVFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const CvActions = CvBuilderSlice.actions;
export default CvBuilderSlice;
