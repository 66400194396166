import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  user: [],
  company: [],
  loadingForgetPassword: false,
  errorForgetPassword: null,
  ForgetPassword: [],
  changepassword: [],
};

const AuthSlice = createSlice({
  name: "AuthReducers",
  initialState,
  reducers: {
    LoginRequest: (state) => {
      state.loading = true;
    },
    LoginSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload;
    },
    LoginFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    changepasswordRequest: (state) => {
      state.loading = true;
    },
    changepasswordSuccess: (state, action) => {
      state.loading = false;
      state.changepassword = action.payload;
      state.error = null;
    },
    changepasswordFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    RegisterRequest: (state) => {
      state.loading = true;
    },
    RegisterSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = false;
    },
    RegisterFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.user = false;
    },
    RegisterCompanyReq: (state, action) => {
      state.loading = false;
    },
    RegisterCompanySuccess: (state, action) => {
      state.loading = false;
      state.company = action.payload;
      state.error = false;
    },
    RegisterCompanyFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.company = false;
    },
    logout: (state) => {
      state.user = [];
    },
    ForgetPasswordRequest: (state) => {
      state.loadingForgetPassword = true;
    },
    ForgetPasswordSuccess: (state, action) => {
      state.loadingForgetPassword = false;
      state.ForgetPassword = action.payload;
    },
    ForgetPasswordFail: (state, action) => {
      state.loadingForgetPassword = false;
      state.errorForgetPassword = action.payload;
    },
  },
});

export const AuthActions = AuthSlice.actions;
export default AuthSlice;
