import React, { useState, useEffect } from "react";
import "./JobDetails.css";
import heading from "../../assets/images/aboutus/Path 489.svg";
import red_icon from "../../assets/images/profile/red_icon.png";
import JobListingCard from "../../Components/Profile/JobListCard";
import { ImFacebook2 } from "react-icons/im";
import { BsBuildingFillCheck } from "react-icons/bs";
import { ImLinkedin } from "react-icons/im";
import { GrUserSettings } from "react-icons/gr";
import { BsPhone } from "react-icons/bs";
import { IoMailOutline } from "react-icons/io5";
import { TbWorld } from "react-icons/tb";
import icon1 from "./../../assets/images/profile/Group_451.png";
import icon2 from "./../../assets/images/profile/Group_447.png";
import icon3 from "./../../assets/images/profile/Group_453.png";
import icon4 from "./../../assets/images/profile/Path 998.png";
import icon5 from "./../../assets/images/profile/Path 998.png";
import yellowbg from "../../assets/images/home/Path 192.webp";
import greenbg from "../../assets/images/home/Path 193.webp";
import purple from "../../assets/images/home/Path 194.webp";
// import Group147 from "./../../assets/images/header/";
import redbg from "../../assets/images/home/Path 191.webp";
import { useSelector, useDispatch } from "react-redux";
import {
  getJobById,
  getJobs2,
  applyjob,
  getifCheck,
} from "../../Redux/jobs/JobsActions";
import { useParams } from "react-router-dom";
import Loader from "../../Components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import isAuth from "../../Utils/isAuth";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

function JobDetails() {
  const createNotification = (type) => {
    return () => {
      switch (type) {
        case "info":
          NotificationManager.info("Info message");
          break;
        case "success":
          NotificationManager.success(
            "Check your email regulary to stay update on your application",
            "Apply Succesfully"
          );
          break;
        case "warning":
          NotificationManager.warning(
            "Warning message",
            "Close after 3000ms",
            3000
          );
          break;
        case "error":
          NotificationManager.error("Error message", "Click me!", 5000, () => {
            alert("callback");
          });
          break;
      }
    };
  };
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getJobs2());
  }, []);
  const [message, setmessage] = useState(false);
  const navigate = useNavigate();
  const { jobid, jobs, apply, isApply, loading } = useSelector(
    (store) => store.Jobs
  );

  const handleapply = async () => {
    if (!isAuth(dispatch)) {
      navigate("/login");
      return;
    }
    try {
      dispatch(applyjob(id));
      createNotification("success")();
    } catch (error) {
      console.error("Error applying for the job:", error);
    }
  };

  useEffect(() => {
    dispatch(getJobById(id));
    dispatch(getifCheck(id));
  }, [id]);
  useEffect(() => {
    if (!isApply) dispatch(getifCheck(id));
  }, [isApply]);

  useEffect(() => {
    if (apply.length > 0) {
      setmessage(true);
    }
  }, [apply]);
  const [jobdes, setjobdesc] = useState([]);
  useEffect(() => {
    if (jobid?.data) {
      setjobdesc(jobid?.data);
    }
  }, [jobid]);

  if (loading) {
    return <Loader />;
  } else {
    return (
      <div className="Main_job_details">
        <NotificationContainer />
        <div className="Aboutus_heading">
          <div className="Aboutus_heading_img">
            <img src={heading} alt="heading" className="heading" />
          </div>
        </div>
        <div className="profile_cont">
          <div className="profile_img_cont">
            <div className="profile_img_pro">
              {" "}
              <div className="home_stats_cont">
                <div className="home_stats_item_icon">
                  <img
                    src={redbg}
                    alt="building"
                    className="home_stats_icon1111"
                  />
                </div>
                <div className="home_stats_item_text1212">
                  <div className="home_stats_item_number111">
                    {jobid.category?.category?.title}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="FirstPart_Container">
          <div className="small_cont">
            <div className="Job_Title"> {jobdes.title_en} </div>
            {jobdes.expired === true ? (
              <button className="Apply_button">Expired</button>
            ) : !isApply?.data ? (
              <button
                className="Apply_button"
                onClick={() => {
                  handleapply();
                  createNotification("success");
                }}
              >
                Apply
              </button>
            ) : (
              <p
                style={{
                  color: "green",
                  fontWeight: "bold",
                  fontFamily: "cursive",
                }}
              >
                Matching or Disqualified
              </p>
            )}
          </div>
          {message && <div className="message_app"> Applied Successfully </div>}

          <div className="Job_Title_info"> {jobdes?.expertise?.name_en}</div>
          <div className="Icons_Container">
            <a href={`tel:${jobdes?.company?.phone_number}`}>
              <div className="label">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <BsPhone size={30} /> {jobdes?.company?.phone_number}
                </div>
              </div>
            </a>
            <a href={`mailto:${jobdes?.company?.hr_email}`}>
              <div className="label">
                <IoMailOutline size={30} /> {jobdes?.hr_email}
              </div>
            </a>

            <div className="label">
              {" "}
              <TbWorld size={30} /> Post Date: {jobdes?.date}
            </div>
            <div
              className="label"
              onClick={() => navigate(`/company/${jobdes?.company_id}`)}
            >
              {" "}
              <BsBuildingFillCheck size={30} /> {jobdes?.company?.full_name}
            </div>
          </div>
        </div>
        <div className="SecondPart_Container">
          <div className="containersecond">
            <div className="home_stats_cont">
              <div className="home_stats_item_icon">
                <img src={redbg} alt="building" className="home_stats_icon1" />
              </div>
              <div className="home_stats_item_text">
                <div className="home_stats_item_number11">
                  {jobdes.job_type}
                </div>
              </div>
            </div>
            <div className="home_stats_cont">
              <div className="home_stats_item_icon">
                <img
                  src={yellowbg}
                  alt="building"
                  className="home_stats_icon1"
                />
              </div>
              <div className="home_stats_item_text">
                <div className="home_stats_item_number11">
                  {jobdes?.experience_level?.name_en}
                </div>
              </div>
            </div>
            <div className="home_stats_cont">
              <div className="home_stats_item_icon">
                <img
                  src={greenbg}
                  alt="building"
                  className="home_stats_icon1"
                />
              </div>
              <div className="home_stats_item_text">
                <div className="home_stats_item_number11">
                  {jobdes?.education_level?.name_en}
                </div>
              </div>
            </div>
            {/* <div className="home_stats_cont">
            <div className="home_stats_item_icon">
              <img src={purple} alt="building" className="home_stats_icon1" />
            </div>
            <div className="home_stats_item_text">
              <img
                src={icon1}
                alt="building"
                className="home_stats_miniicons"
              />
              <div className="home_stats_item_number">{jobdes.gender}</div>
            </div>
          </div> */}
            {/* <div className="home_stats_cont">
            <div className="home_stats_item_icon">
              <img src={purple} alt="building" className="home_stats_icon1" />
            </div>
            <div className="home_stats_item_text">
              <img
                src={icon5}
                alt="building"
                className="home_stats_miniicons"
              />
              <div className="home_stats_item_number">
                {jobdes.salary} {jobdes.currrency}
              </div>
            </div>
          </div> */}
          </div>
        </div>

        <div className="desc_cont">
          <div className="Title"> Job Description </div>
          <div className="Job_Desc_Text">
            <ol>
              {jobdes.description_en?.split("-").map((segment, index) => (
                <li key={index}>{segment}</li>
              ))}
            </ol>
          </div>
        </div>

        <div className="require_cont">
          <div className="Title"> Requirements </div>
          <div className="Job_Desc_Text">
            <ol>
              {jobdes.requirement_en?.split("-").map((segment, index) => (
                <li key={index}>{segment}</li>
              ))}
            </ol>
          </div>
        </div>
        <div className="qual_div">
          <div className="Title"> Qualifications and Skills</div>
          <div className="Job_Desc_Text">
            <ol>
              {jobdes.qualification_skill_en
                ?.split("-")
                .map((segment, index) => (
                  <li key={index}>{segment}</li>
                ))}
            </ol>
          </div>
        </div>

        <div className="benefits_cont">
          <div className="Title"> Benefits </div>
          <div className="Job_Desc_Text">
            <ol>
              {jobdes.benefit_en?.split("-").map((segment, index) => (
                <li key={index}>{segment}</li>
              ))}
            </ol>
          </div>
        </div>

        <div className="rel_list">
          <div className="Title"> Related Jobs </div>
          <div className="jobs_info">
            {loading ? (
              <Loader />
            ) : (
              <>
                {jobs &&
                  jobs
                    .slice(0, 3)
                    .map((job) => (
                      <JobListingCard
                        key={job.id}
                        position={job.title_en}
                        name={job.company.full_name}
                        location={job.district.name_en}
                        experience={job.experience_level.name_en}
                        education={job.education_level.name_en}
                        gender={job.gender}
                        payment={job.currrency}
                        desc={
                          job &&
                          job.job &&
                          job.job.job_categories &&
                          job.job.job_categories[0] &&
                          job.job.job_categories[0].category
                            ? job.job.job_categories[0].category.title
                            : "No Category"
                        }
                        type={job.job_type}
                        date={job.date}
                        id={job.id}
                        image={job.company.img_url}
                      />
                    ))}
              </>
            )}
            <button
              className="view_button1"
              onClick={() => navigate("/joblisting")}
            >
              {" "}
              View More{" "}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default JobDetails;
