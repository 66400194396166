import React, { useState, useEffect, useRef } from "react";
import "./Header.css";
import logo from "../../assets/images/header/logo.png";
import userLogo from "../../assets/images/header/userIcon.png";
import { AiOutlineSearch } from "react-icons/ai";
import logedin from "../../assets/images/header/logedin.webp";
import { IoIosLogIn } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";
import BurgerMenu from "./BurgerMenu/BurgerMenu";
import { useNavigate , useLocation } from "react-router-dom";
import isAuth from "../../Utils/isAuth";
import { useDispatch, useSelector } from "react-redux";
import { getmyinfo } from "../../Redux/User/UserActions";
import { logout } from "../../Redux/Auth/AuthActions";
import TrackedLink from '../TrackedLink';
import { initGA, logPageView } from '../../analytics';

function Header() {
  const ref = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [islogedin, setislogedin] = useState(false);
  const [activeSection, setactiveSection] = useState("");
  const [showburger, setshowburger] = useState(false);
  const [profiledropdown, setprofiledropdown] = useState(false);
  const { userinfo } = useSelector((state) => state.User);
  const [searchdata, setsearchdata] = useState("");
  const [headerOptions, setHeaderOptions] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const hostname = window.location.hostname;
    initGA(hostname);
  }, []);

  useEffect(() => {
    logPageView();
  }, [location]);
  
  const Sections = [
    "About us",
    "Jobs",
    "Companies",
    "Our impact",
    // "Trainings",
    "Get involved",
    "Platforms",
  ];

  const SectionsLoggedIn = [
    "About us",
    "Jobs",
    "Companies",
    "Our impact",
    // "Trainings",
    "Get involved",
  ];

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token === null) {
      setHeaderOptions(Sections);
    } else {
      setHeaderOptions(SectionsLoggedIn);
    }
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (profiledropdown && ref.current && !ref.current.contains(e.target)) {
        setprofiledropdown(!profiledropdown);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [profiledropdown]);
  const logoutuser = () => {
    dispatch(logout(navigate));

    // navigate("/");
    // window.location.reload();
  };

  const handleOnClick = (section) => {
    setactiveSection(section);
    if (section === "About us") {
      navigate("/aboutus");
    }
    if (section === "Get involved") {
      navigate("/contactus");
    }
    if (section === "Companies") {
      navigate("/companies");
    }
  };

  const handleOnMouseLeave = () => {
    setactiveSection(null);
  };
  const handleOnMouseEnter = (section) => {
    setactiveSection(section);
  };
  useEffect(() => {
    setislogedin(isAuth(dispatch));
  }, [islogedin]);
  useEffect(() => {
    if (islogedin) {
      dispatch(getmyinfo());
    }
  }, [islogedin]);
  useEffect(() => {
    if (searchdata.length > 0) {
      navigate("/?search=" + searchdata);
    }
  }, [searchdata]);

  return (
    <div className="Main_header_div">
      <div
        className="Header_container"
        onMouseLeave={() => handleOnMouseLeave()}
      >
        <div
          className="Header_logo_container"
          onClick={() => navigate("/")}
          onMouseLeave={() => handleOnMouseLeave()}
        >
          <img
            src={logo}
            alt="logo"
            className="logo_header"
            onMouseLeave={() => handleOnMouseLeave()}
          />
        </div>
        <div className="Header_nav_container">
          {headerOptions.map((section) => (
            <div
              key={section}
              className="Header_nav_item_wrapper"
              onMouseEnter={() => handleOnMouseEnter(section)}
            >
              <a
                className={`Header_nav_item ${activeSection === section ? "active" : ""
                  }`}
                onClick={() => handleOnClick(section)}
              >
                {section}
              </a>
              {activeSection === "Jobs" && section === "Jobs" && (
                <div
                  className="Header_nav_dropdown"
                  onMouseLeave={() => handleOnMouseLeave()}
                >
                  <div className="Header_nav_dropdown_cont">
                    <div
                      className="subsection_text"
                      onClick={() => navigate("/joblisting")}
                    >
                      Find jobs
                    </div>
                    <div
                      className="subsection_text"
                      onClick={() => navigate("/cvbuilder")}
                    >
                      Create your CV{" "}
                    </div>
                  </div>
                </div>
              )}
              {activeSection === "Platforms" && section === "Platforms" && (
                <div
                  className="Header_nav_dropdown_2"
                  onMouseLeave={() => handleOnMouseLeave()}
                >
                  <div className="Header_nav_dropdown_cont">
                    <div className="subsection_text">


                      <TrackedLink url="https://mehnati.org/">
                        Mehnati
                      </TrackedLink>

                    </div>
                    <div className="subsection_text">
                      <TrackedLink url="https://lms.mehnati.org/">
                        Lms
                      </TrackedLink>
                    </div>
                    <div className="subsection_text">
                      <TrackedLink url="https://schools.mehnati.org/">
                        Schools
                      </TrackedLink>
                    </div>
                  </div>
                </div>
              )}
              {activeSection === "Our impact" && section === "Our impact" && (
                <div
                  className="Header_nav_dropdown"
                  onMouseLeave={() => handleOnMouseLeave()}
                >
                  <div className="Header_nav_dropdown_cont">
                    <div
                      className="subsection_text"
                      onClick={() => navigate("/successstories")}
                    >
                      Sucess Stories
                    </div>
                    {/* <div className="subsection_text">Media Content</div> */}
                    <div
                      className="subsection_text"
                      onClick={() => navigate("/qna")}
                    >
                      Q&A{" "}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="Header_login_container">
          <div
            className="Header_search_bar"
            onMouseLeave={() => handleOnMouseLeave()}
          >
            <input
              className="Header_search_input"
              placeholder="Search for job"
              onChange={(e) => setsearchdata(e.target.value)}
              value={searchdata}
            />

            <AiOutlineSearch className="Header_search_icon" size={30} />
          </div>
          {islogedin ? (
            <>
              <div
                ref={ref}
                className="Header_logedin_profile"
                onClick={() => setprofiledropdown(!profiledropdown)}
              >
                <img
                  src={process.env.REACT_APP_API + "/user/" + userinfo.img_url}
                  alt="logedin"
                  className="Header_logedin_profile_image_icon"
                />
                {profiledropdown && (
                  <div className="Header_nav_dropdown_profile">
                    <div className="Header_nav_dropdown_cont">
                      <div
                        className="subsection_text"
                        onClick={() => navigate("/profile")}
                      >
                        Profile
                      </div>
                      <div
                        className="subsection_text"
                        onClick={() => navigate("/ChangePassword")}
                      >
                        Change Password
                      </div>
                      <div className="subsection_text" onClick={logoutuser}>
                        Logout{" "}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div
                className="Header_logedin_profile"
                onClick={() => navigate("/auth")}
              >
                <img
                  src={userLogo}
                  alt="logo"
                  className="Header_logedin_profile_image_icon"
                  size={25}
                />
              </div>
            </>
          )}
        </div>
        <div
          className="Header_burger_menu"
          onClick={() => setshowburger(!showburger)}
        >
          <RxHamburgerMenu className="Header_burger_icon" size={30} />
        </div>
      </div>
      {showburger && (
        <BurgerMenu isShown={showburger} setIsShown={setshowburger} />
      )}
    </div>
  );
}

export default Header;
