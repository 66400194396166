import axios from "axios";
import { QuestionsAnswersActions } from "./QuestionsAnswersReducers";

export const getAllQuestionsAnswers = () => async (dispatch) => {
  try {
    dispatch(QuestionsAnswersActions.QuestionsAnswersRequest());
    const res = await axios.get(process.env.REACT_APP_API + "/qa/",
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
    );
    dispatch(QuestionsAnswersActions.QuestionsAnswersSuccess(res.data.data));
  } catch (error) {
    dispatch(QuestionsAnswersActions.QuestionsAnswersFail(error));
  }
};
