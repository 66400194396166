import React, { useState, useEffect } from "react";
import "./Login.css";
import heading from "../../assets/images/CompleteProfile/Path 489.png";
import { FiCheck } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { user_Login, forgetPassword } from "../../Redux/Auth/AuthActions";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
function Login() {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [confirm, setconfirm] = useState("");
  const [forgetPasswordError, setForgetPasswordError] = useState("");
  const dispatch = useDispatch();
  const { user, loading, error, errorForgetPassword } = useSelector(
    (store) => store.Auth
  );
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  const [disabled, setdisabled] = useState(true);
  useEffect(() => {
    setForgetPasswordError("");
  }, []);
  const handleLogin = (e) => {
    e.preventDefault();
    setForgetPasswordError("");
    dispatch(user_Login(email, password));
  };

  const handleForgetPassword = (e) => {
    e.preventDefault();
    setForgetPasswordError("");
    if (email.length > 0) {
      dispatch(forgetPassword(email));
    }
  };
  useEffect(() => {
    if (errorForgetPassword) {
      setForgetPasswordError(errorForgetPassword);
    }
  }, [errorForgetPassword]);
  const [showpassword, setshowpassword] = useState(false);
  const [showpassword1, setshowpassword1] = useState(false);
  const handlePaste = (event) => {
    event.preventDefault();

    // Access the clipboard data
    const clipboardData = event.clipboardData || window.clipboardData;

    // Get the pasted text from the clipboard
    const pastedText = clipboardData.getData("text/plain");

    // Do something with the pasted text (e.g., display an alert)
  };
  useEffect(() => {
    if (email === "" || !emailRegex.test(email) || password === "") {
      setdisabled(true);
    } else {
      setdisabled(false);
    }
  }, [email, password]);

  return (
    <div className="Main_login">
      <div className="Aboutus_heading">
        <div className="Aboutus_heading_img">
          <img src={heading} alt="heading" className="heading" />
        </div>

        <div className="Aboutus_heading_text">
          <div className="Aboutus_heading_text_title"> Technicians Login</div>
          <div className="Aboutus_heading_text_desc">
            {" "}
            <a href="/">Home /</a>
            <span className="head_span">Login</span>
          </div>
        </div>
      </div>
      <div className="login_form_cont">
        <div className="login-cont">
          <form>
            <div className="signup_data">
              <div className="null_container">
                {email !== "" && emailRegex.test(email) && (
                  <FiCheck size={30} color={"#A2BF83"} />
                )}
              </div>
              <input
                type="email"
                placeholder="Email Address"
                // className="input_complete_1"
                className={
                  email !== "" ? "input_complete_1_check" : "input_complete_1"
                }
                required
                onChange={(e) => setemail(e.target.value)}
              />
            </div>
            <div className="signup_data">
              <div className="null_container">
                {password !== "" && <FiCheck size={30} color={"#A2BF83"} />}
              </div>
              <input
                type={showpassword ? "text" : "password"}
                placeholder="Password"
                // className="input_complete_1"
                className={
                  password !== "" && password === confirm
                    ? "input_complete_1_check"
                    : "input_complete_1"
                }
                required
                onChange={(e) => setpassword(e.target.value)}
                onPaste={handlePaste}
              />
              {!showpassword && (
                <div
                  className="eye"
                  onClick={() => setshowpassword(!showpassword)}
                >
                  <AiFillEye size={30} />
                </div>
              )}
              {showpassword && (
                <div
                  className="eye"
                  onClick={() => setshowpassword(!showpassword)}
                >
                  <AiFillEyeInvisible size={30} />
                </div>
              )}
            </div>
          </form>

          <p className="allabove">All above fields are required*</p>

          {error && <p className="error"> {error?.response?.data?.message} </p>}
          {forgetPasswordError && (
            <p className="error">
              {" "}
              {forgetPasswordError.response.data.message}{" "}
            </p>
          )}
          <div className="button_container" onClick={handleLogin}>
            <button className="nextbutton" type="submit" disabled={disabled}>
              {" "}
              Sign In{" "}
            </button>
          </div>
          <div className="forget_pasword" onClick={handleForgetPassword}>
            Forgot my Password
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
