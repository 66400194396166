import React from "react";
import "./ProfileCard.css";

function ProfileCard(props) {
  return (
    <div className="Main_ProfileCard">
      <div>
        {" "}
        <div className={props.className}> {props.subtitle} </div>
      </div>
      <div className="ProfileCard_container">
        <div className="ProfileCard_container_down">
          <div className={props.classNameB}>{props.buttontext}</div>
          <div className="PROFILE_CARD_DATE">{props.date}</div>
          <div className="PROFILE_CARD_NAME"> {props.text}</div>
          <div className="profile_card_button">
            <a href={props.link} target="_blank">
              {" "}
              <button className="PROFILE_CARD_PREVIEW">Preview </button>{" "}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileCard;
