import "./App.css";
import PageSwitch from "./Pages/PageSwitch";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./Redux/Store";
import { Provider } from "react-redux";
function App() {
  return (
    <div className="App">
      <Router basename="/">
        <Provider store={store}>
          <PageSwitch />
        </Provider>
      </Router>
    </div>
  );
}

export default App;
