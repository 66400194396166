import React, { useState, useEffect } from "react";
import "./JobsCard.css";
import { useNavigate } from "react-router-dom";

function JobsCard(props) {
  const navigate = useNavigate();
  const [statusClass, setstatusClass] = useState("");

  const changeStatusClass = () => {
    let status = props.status.toLowerCase();
    if (status === "full time") {
      setstatusClass("pinkcolor");
    } else if (status === "part-time") {
      setstatusClass("greencolor");
    } else if (status === "internship") {
      setstatusClass("purplecolor");
    } else {
      setstatusClass("pinkcolor");
    }
  };
  useEffect(() => {
    changeStatusClass();
  }, [props.status]);

  return (
    <div className="Main_stories_div">
      <div
        className="jobs_card"
        onClick={() => navigate(`/jobdetails/${props.id}`)}
      >
        <div className="jobs_card_img_cont">
          <img
            src={process.env.REACT_APP_API + "/company/" + props.image}
            alt="stories"
            className="jobs_card_img"
          />
        </div>
        <div className="jobs_card_text_cont">
          {/* <div className="stories_card_status">{props.status}</div> */}
          <div className={`jobs_card_status ${statusClass}`}>
            {props.status}
          </div>
          <div className="jobs_card_date">{props.date}</div>
          <div className="jobs_card_name">{props.name}</div>
          <div className="jobs_card_text">
            {props.text.length <= 100
              ? props.text
              : `${props.text.slice(0, 100)}..`}
          </div>
          <div
            className="jobs_card_btn"
            onClick={() => navigate(`/jobdetails/${props.id}`)}
          >
            Read More
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobsCard;
