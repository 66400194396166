import React, { useState } from "react";
import "./CategorieCard.css";
import { useNavigate } from "react-router-dom";

function CategorieCard(props) {
  const navigate = useNavigate();

  const [hovered, setHovered] = useState(false);

  const handleClick = () => {
    navigate(`/joblisting/?category=${props.id}`);
  };

  return (
    <div className="Main_div" onClick={handleClick}>
      <div
        className="card_cont"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <div className="card_img_cont">
          <img
            src={props.img}
            alt="cardimg"
            className={hovered ? "card_img_1" : "card_img"}
          />
        </div>
        <div className="card_text_cont">
          <div className={hovered ? "card_title_1" : "card_title"}>
            {props.title}
          </div>
          <div className={hovered ? "card_desc_1" : "card_desc"}>
            ( open position {props.desc} )
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategorieCard;
