import React from "react";
import "./Loader.css";
import imagefile from "./../../assets/images/mehnati loading gif.gif"
function Loader() {
  return (
    <div className="Main_div_load">
     <img src={imagefile}/>
    </div>
  );
}

export default Loader;
