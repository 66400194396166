import React, { useState, useEffect } from "react";
import "./Home.css";
//images
import dashboard from "../../assets/images/home/dashboardBg.webp";
//icons
import building from "../../assets/images/home/Group 9.webp";
import suitcase from "../../assets/images/home/Group 15.webp";
import usericon from "../../assets/images/home/Group 13.webp";
// import iconread from "../../assets/images/home/Group 27.webp";
import shakehand from "../../assets/images/home/Group 14.webp";
//bgs
import redbg from "../../assets/images/home/pngs-83.png";
import yellowbg from "../../assets/images/home/pngs-81.png";
import greenbg from "../../assets/images/home/pngs-82.png";
import purple from "../../assets/images/home/pngs-80.png";
// import navy from "../../assets/images/home/Path 195.webp";
import SucessStories from "../../Components/SucessStories/SucessStories";
import JobsCard from "../../Components/JobsCard/JobsCard";
import suitcase1 from "../../assets/images/home/suitcase.webp";
import Section5 from "../../assets/images/home/section5.webp";
import CategorieCard from "../../Components/CategorieCard/CategorieCard";
import cardimg1 from "../../assets/images/home/cardimg1.webp";
import roadster from "../../assets/images/home/roadster logo@1.5x.png";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, useNavigate } from "react-router-dom";
import isAuth from "../../Utils/isAuth";
import { useDispatch, useSelector } from "react-redux";
import { getJobs2 } from "../../Redux/jobs/JobsActions";
import { getAllCategory } from "../../Redux/Activity/ActivityActions";
import { getSuccessStories } from "../../Redux/SuccessStories/SuccessStoriesActions";
import {
  CompanyStats,
  getstatistics,
} from "../../Redux/CompanyStats/CompanyStatsActions";
import { useLocation } from "react-router-dom";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

export default function Home(route) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchdata = searchParams.get("search");

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1400 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1400, min: 940 },
      items: 5,
    },
    mobile: {
      breakpoint: { max: 940, min: 0 },
      items: 1,
    },
  };

  const { successStories } = useSelector((store) => store.SuccessStories);
  const { Category, loading1, error1 } = useSelector(
    (store) => store.Activities
  );
  const [jobdata, setJobdata] = useState([]);
  useEffect(() => {
    dispatch(getSuccessStories());
    dispatch(CompanyStats());
    dispatch(getstatistics());
    dispatch(getAllCategory());
  }, []);

  useEffect(() => {
    dispatch(getJobs2(searchdata));
  }, [searchdata]);
  const { jobs } = useSelector((store) => store.Jobs);
  const { Company, statistics } = useSelector((store) => store.CompanyStatss);

  useEffect(() => {
    if (searchdata) {
      window.scrollTo(0, 0); // Scroll to top of the page
    }
  }, [searchdata]);
  //initialize dd with 6 usestate
  const [dd, setdd] = useState(6);
  //function that check if dd is 6 setdd100 else set it 6
  const checkdd = () => {
    if (dd == 6) {
      setdd(100);
    } else {
      setdd(6);
    }
  };

  return (
    <div className="Main_home_div">
      <div className="home_section_cont">
        {searchdata && (
          <div className="home_section2">
            {jobs.length > 0 ? (
              jobs.map((item) => (
                <JobsCard
                  image={item.company.img_url}
                  status={item.job_type}
                  date={item.date}
                  name={item.title_en}
                  text={item.description_en}
                  id={item.id}
                />
              ))
            ) : (
              <p className="nomatch">
                Oops ... We didnt find anything that matches this search
              </p>
            )}
          </div>
        )}

        <div className="home_career">
          <div className="home_career_text_cont">
            <div className="home_career_title">
              Start your career journey with us
            </div>
          </div>
          <div className="home_career_img_container">
            <img src={dashboard} alt="dashboard" className="home_career_img" />
          </div>
        </div>
        <div className="home_stats">
          <div className="home_stats_cont">
            <div className="home_stats_item_icon">
              <img src={redbg} alt="building" className="home_stats_icon" />
            </div>
            <div className="home_stats_item_text">
              <div className="home_stats_item_number">
                {/* {statistics?.company} */}
                160
              </div>
            </div>
          </div>
          <div className="home_stats_cont">
            <div className="home_stats_item_icon">
              <img src={yellowbg} alt="building" className="home_stats_icon" />
            </div>
            <div className="home_stats_item_text">
              <div className="home_stats_item_number">
                {/* {statistics?.job} */} 165
              </div>
            </div>
          </div>
          <div className="home_stats_cont">
            <div className="home_stats_item_icon">
              <img src={greenbg} alt="building" className="home_stats_icon" />
            </div>
            <div className="home_stats_item_text">
              <div className="home_stats_item_number">
                {/* {statistics?.user} */}
                200
              </div>
            </div>
          </div>
          <div className="home_stats_cont">
            <div className="home_stats_item_icon">
              <img src={purple} alt="building" className="home_stats_icon" />
            </div>
            <div className="home_stats_item_text">
              <div className="home_stats_item_number">35</div>
            </div>
          </div>
          {/* <div className="home_stats_cont">
            <div className="home_stats_item_icon">
              <img src={navy} alt="building" className="home_stats_icon" />
            </div>
            <div className="home_stats_item_text">
              <img
                src={shakehand}
                alt="building"
                className="home_stats_miniicons"
              />
              <div className="home_stats_item_number">50</div>

              <div className="home_stats_item_title">Trainees</div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="home_section2_cont">
        <div className="home_career_text_cont">
          <div className="home_career_title">Success Stories</div>
        </div>
        <div className="home_section2">
          {successStories.slice(1, 4).map((item) => (
            <SucessStories
              img={item.img_url}
              status={item.status}
              date={item.time}
              name={item.full_name}
              text={item.information}
              position={item.title}
            />
          ))}
        </div>
      </div>
      <div className="home_section3_cont">
        <div className="home_career_text_cont">
          <div className="home_career_title">
            <img src={suitcase1} className="icon_logo" />
            Recent Jobs
          </div>
        </div>
        <div className="home_section2">
          {jobs.slice(0, 3).map((item) => {
            return (
              <JobsCard
                image={item.company.img_url}
                status={item.job_type}
                date={item.date}
                name={item.title_en}
                text={item.description_en}
                id={item.id}
              />
            );
          })}
          {/* <JobsCard
            img={dashboard}
            status={"Internship"}
            date={"07-06-2022"}
            name={"Accountant"}
            text={
              "information about the success story information about the success story"
            }
          /> */}
        </div>
        <div
          className="home_section3_button"
          onClick={() => navigate("/joblisting")}
        >
          <div className="home_section3_button_text">See All Jobs</div>
        </div>
      </div>
      <div className="home_section4_cont">
        <div className="home_section4_1">
          <div className="home_career_text_cont_4">
            <div className="home_career_title_sec4"> Are you a job seeker?</div>
            <div className="home_career_subtitle_sec4">
              {" "}
              Search, Apply & Get a Job
            </div>
            <div className="home_career_title2_sec4"> Join Us Now</div>
            <div className="home_section4_button">
              <div
                className="home_section4_button_text"
                onClick={() => navigate("/completeprofile")}
              >
                Register
              </div>
            </div>
          </div>
        </div>
        <div className="home_section4_2">
          {" "}
          <div className="home_career_text_cont_4">
            <div className="home_career_title_sec4"> Are you an employer?</div>
            <div className="home_career_subtitle_sec4">
              {" "}
              Find and hire Professionals
            </div>
            <div className="home_career_title2_sec4"> Join Us Now</div>
            <div className="home_section4_button">
              <div
                className="home_section4_button_text"
                onClick={() => navigate("/registercompany")}
              >
                Register
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home_section5_cont">
        <div className="home_section5_cont_all">
          <div className="home_section5_text_container">
            <div className="home_section5_title">
              Create a Professional CV Quickly and Easily!{" "}
            </div>
            <div
              className="home_section5_button_cont"
              onClick={() => navigate("/cvbuilder")}
            >
              <div className="home_section5_button"> Create your CV </div>
            </div>
            <div className="home_section5_subtitle">
              If you already have a CV upload it here
            </div>
            <div
              className="home_section5_button_cont"
              onClick={() => navigate("/profile")}
            >
              <div className="home_section5_button"> Upload your CV </div>
            </div>
          </div>
          <div className="home_section5_img_container">
            <img src={Section5} className="home_section5_img" />
          </div>
        </div>
      </div>
      <div className="home_section6_cont">
        {" "}
        <div className="home_career_text_cont">
          <div className="home_cat_title">
            <img src={suitcase1} className="icon_logo" />
            Popular Categories
          </div>
        </div>
        <div className="cat_all">
          {Category?.slice(0, dd)
            .sort((a, b) => b.job_category_count - a.job_category_count) // Sort in descending order
            .map((cat) => (
              <CategorieCard
                key={cat.id}
                img={`${process.env.REACT_APP_API}/category/${cat.image}`}
                title={cat.title}
                desc={cat.job_category_count}
                id={cat.id}
              />
            ))}
        </div>
        <div className="cat_button" onClick={() => checkdd()}>
          {dd == 6 ? <div>See All Categories</div> : <div>See Less</div>}
        </div>
      </div>

      <div className="home_section7_cont">
        <div className="home_section7_cont_title"> Companies</div>

        <Carousel
          responsive={responsive}
          showThumbs={false}
          showDots={false}
          autoPlay={true}
          arrows={true}
          draggable={true}
          swipeScrollTolerance={50}
          centerMode={true}
          containerClass="carousel-item1"
          autoPlaySpeed={3000}
        >
          {Company?.slice(0, Company.length / 2).map((camp) => (
            <div key={camp.id}>
              <img
                src={process.env.REACT_APP_API + "/company/" + camp.img_url}
                className="companies_img"
                alt={`Company ${camp.id}`}
              />
            </div>
          ))}
        </Carousel>

        <br />

        <Carousel
          responsive={responsive}
          showThumbs={false}
          showDots={false}
          autoPlay={true}
          arrows={true}
          draggable={true}
          swipeScrollTolerance={50}
          centerMode={true}
          containerClass="carousel-item1"
          autoPlaySpeed={3000}
        >
          {Company?.slice(Company.length / 2, Company?.length).map((camp) => (
            <div key={camp.id}>
              <img
                src={process.env.REACT_APP_API + "/company/" + camp.img_url}
                className="companies_img"
                alt={`Company ${camp.id}`}
              />
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}
