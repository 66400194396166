import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  successStories: [],
  successStoriesLoading: false,
  successStoriesError: "",
};

const successStoriesSlice = createSlice({
  name: "successStories",
  initialState,
  reducers: {
    successStoriesRequest: (state) => {
      state.successStoriesLoading = true;
    },
    successStoriesSuccess: (state, action) => {
      state.successStoriesLoading = false;
      state.successStories = action.payload;
    },
    successStoriesFail: (state, action) => {
      state.successStoriesLoading = false;
      state.successStoriesError = action.payload;
    },
  },
});

export const SuccessStoriesAction = successStoriesSlice.actions;
export default successStoriesSlice;
