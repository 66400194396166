import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  Company: [],
  statistics: [],
};

const CompanyStatsSlice = createSlice({
  name: "CompanyStatsReducers",
  initialState,
  reducers: {
    CompanyStatsRequest: (state) => {
      state.loading = true;
    },
    CompanyStatsSuccess: (state, action) => {
      state.loading = false;
      state.Company = action.payload;
    },
    CompanyStatsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    statisticsRequest: (state) => {
      state.loading = true;
    },
    statisticsSuccess: (state, action) => {
      state.loading = false;
      state.statistics = action.payload;
    },
    statisticsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const CompanyStatsActions = CompanyStatsSlice.actions;
export default CompanyStatsSlice;
